import { Modal } from 'src/components/common/Modal'
import Icon from 'src/components/ui/Icon'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import type { CommercialBenefit } from 'src/components/product-page/context/types/product.type'
import './styles.scss'

type ModalComercialBenefits = {
  openModalCommercialsBenefits: boolean
  comercialBenefitsData: CommercialBenefit
  handleCloseCommercialsBenefitsModal: (value: boolean) => void
}

export function ModalCommercialsBenefits({
  handleCloseCommercialsBenefitsModal,
  openModalCommercialsBenefits,
  comercialBenefitsData,
}: ModalComercialBenefits) {
  const { link } = comercialBenefitsData
  const { modal } = link

  return (
    <Modal
      onClose={() => {
        handleCloseCommercialsBenefitsModal(false)
      }}
      isOpen={openModalCommercialsBenefits}
      className="rounded-lg w-[90%] md:w-full max-w-lg pp:max-h-[95vh] pp:min-h-fit"
      opacityTailwind="opacity-80"
    >
      <button
        onClick={() => {
          handleCloseCommercialsBenefitsModal(false)
        }}
        className="absolute top-5 right-5"
      >
        <Icon
          fill={modal?.colorCloseIcon}
          name="Close"
          width={24}
          height={24}
        />
      </button>

      <div
        className="htmlTextModal text-sm font-roboto text-deepGray"
        dangerouslySetInnerHTML={{ __html: modal?.html ?? '' }}
      />
    </Modal>
  )
}
