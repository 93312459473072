import { useRef } from 'react'
import { Modal } from 'src/components/restructure/common/Modal'
import * as yup from 'yup'
import { InputMask } from 'src/components/common/Input'
import { useCepStorage } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import type { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Icon from 'src/components/ui/Icon/Icon'
import { useProductPageContext } from 'src/components/restructure/product/contexts'
import {
  makeAddToCartEvent,
  makeCheckoutShippingEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { Statsig } from 'statsig-react'
import { useBuyButton } from 'src/components/restructure/product/sections/Sidebar/ProductAction/BuyButton/hooks/useBuyButton'

import type { Item } from '../useFreightSimulation'

type Props = {
  isOpen: boolean
  items: Item[]
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

type IFormValues = {
  cep: string
}

const schema = yup
  .string()
  .required('CEP não informado')
  .matches(/^[0-9]{2}[0-9]{3}-[0-9]{3}$/, 'CEP invalido')

export function FreightModal({ isOpen, items, setOpenModal }: Props) {
  const { mapperCartItem, addToCart } = useBuyButton()
  const {
    product,
    currentSku,
    setFreightSimulation,
    triedToAdd,
    specificationMustBeSelected,
    deliveryIsAvailable,
    setTriedToAdd,
  } = useProductPageContext()

  const formRef = useRef<FormHandles>(null)
  const { cepStorage, setCepStorage } = useCepStorage()

  const handleAddItemToCart = () => {
    if (
      !triedToAdd ||
      specificationMustBeSelected !== null ||
      !deliveryIsAvailable
    ) {
      return
    }

    const addToCartEvent = makeAddToCartEvent([
      { product, productItem: currentSku },
    ])

    sendEvent(addToCartEvent)
    Statsig.logEvent('add_to_cart')

    const cartItem = mapperCartItem(product, currentSku)

    addToCart(cartItem)
    setTriedToAdd(false)
  }

  const handleSubmit: SubmitHandler<IFormValues> = async ({ cep }) => {
    const validCep = cep !== '' ? cep : String(cepStorage)

    try {
      await schema.validate(validCep, { abortEarly: false })
      formRef.current?.setFieldError('cep', '')
      setCepStorage(validCep)
      localStorage.setItem('cep', validCep)

      await setFreightSimulation(items, validCep)

      const checkoutShippingEvent = makeCheckoutShippingEvent(
        product,
        currentSku
      )

      sendEvent(checkoutShippingEvent)

      handleAddItemToCart()
      setOpenModal(false)
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        formRef.current?.setFieldError('cep', err.message)

        return
      }

      formRef.current?.setFieldError(
        'cep',
        'Número de CEP inválido. Utilize outro CEP.'
      )
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpenModal(false)}
        className="min-h-[480px] restructure-tablet:min-h-[360px] restructure-small-desktop:min-h-fit restructure-small-desktop:rounded-md restructure-small-desktop:max-w-[520px]"
      >
        <header className="flex items-center justify-between py-[18px] px-lg border-b border-restructure-border-primary">
          <p className="desktop-body-regular-text1 text-restructure-primary">
            Consulte frete e retirada
          </p>
          <button aria-label="close modal" onClick={() => setOpenModal(false)}>
            <Icon name="FreightClose" width={24} height={24} />
          </button>
        </header>

        <main className="min-h-fit mt-lg px-4 restructure-tablet:px-4">
          <p className="desktop-body-regular-text2 text-restructure-tertiary">
            A Decathlon oferece diferentes opções de frete e retirada em loja.
            Adicione seu CEP para ver a disponibilidade em sua região.
          </p>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            data-testid="form"
            className="mt-lg flex items-center flex-col restructure-tablet:flex-row"
          >
            <div className="flex items-center bg-restructure-background-secondary rounded-round gap-2 w-full restructure-tablet:w-auto flex-1 px-2 mr-2">
              <Icon name="FreightLocation" width={16} height={16} />
              <InputMask
                name="cep"
                mask="99999-999"
                className="h-[48px] w-full border-none outline-none pl-0 bg-transparent"
                classNameLabel="flex-1 w-full md:w-auto bg-transparent"
                placeholder={cepStorage ?? 'Informe seu cep'}
                id="inputCep"
              />
            </div>

            <button
              data-testid="calculateShipping"
              className="mt-4 restructure-tablet:mt-0 bg-restructure-background-primary-inverted rounded-round px-lg py-sm desktop-body-regular-text2 text-restructure-primary-inverted min-w-[170px] w-full restructure-tablet:w-auto flex items-center justify-center"
            >
              Consultar frete
            </button>
          </Form>
        </main>

        <footer className="bg-restructure-background-secondary flex items-start gap-2 p-3 restructure-tablet:mt-6 restructure-small-desktop:mt-8 my-6 mx-4">
          <Icon
            className="restructure-small-desktop:w-[24px] restructure-tablet:w-[16px] w-[32px]"
            name="FreightAlert"
            width={16}
            height={16}
          />
          <p className="desktop-caption-regular text-restructure-tertiary">
            Atenção! O preço das entregas dos produtos Decathlon podem variar de
            acordo com a região em que você está!
          </p>
        </footer>
      </Modal>
    </>
  )
}
