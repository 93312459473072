import { useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { Image } from 'src/components/ui/Image'
import type { Options } from '@splidejs/splide'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import type {
  GalleryImageMobileProps,
  Video,
  Image as TImage,
} from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'
import { formatSrc } from 'src/components/restructure/utils/formatSrc'

import { GallerySkeleton } from '../GallerySkeleton'
import { GalleryMovie } from '../GalleryMovie'
import { GalleryFlags } from '../GalleryFlags'
import { GalleryFullView } from '../GalleryFullView'
import './index.scss'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

export const GalleryImageMobile = ({
  images,
  video,
}: GalleryImageMobileProps) => {
  const [isOpenGalleryFullView, setIsOpenGalleryFullView] = useState(false)
  const [selectedImageForClick, setSelectedImageForClick] = useState<TImage>(
    images[0]
  )

  const openGalleryView = (image: TImage, event: { target: any }) => {
    setIsOpenGalleryFullView(true)
    setSelectedImageForClick(image)

    const eventTrack = makeEventTrack({
      eventAction: event.target.ariaLabel,
      eventPage: 'pagina de produto',
    })

    sendEvent(eventTrack)
  }

  const closeGalleryView = () => {
    setIsOpenGalleryFullView(false)
  }

  const itemsGallery: Array<TImage | Video> = video
    ? [...images.slice(0, 1), video, ...images.slice(1)]
    : images

  const mainOptions: Options = {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    pagination: true,
    arrows: false,
  }

  return itemsGallery.length ? (
    <div className="relative gallery-mobile">
      <Splide options={mainOptions} hasTrack={false}>
        <SplideTrack>
          {itemsGallery.slice(0, 7).map((item, index) => (
            <SplideSlide key={index}>
              {index === 0 && <GalleryFlags />}
              {'url' in item ? (
                <GalleryMovie {...item} />
              ) : (
                <Image
                  className="h-full w-full object-cover rounded-md bg-restructure-background-secondary mix-blend-multiply"
                  key={item.imageUrl}
                  src={item.imageUrl}
                  width={628}
                  height={628}
                  srcSet={formatSrc(item.imageUrl, 628, 628)}
                  alt={item.imageText}
                  loading="eager"
                  preload
                  aria-label="Imagem da galeria"
                  onClick={(event) => openGalleryView(item, event)}
                />
              )}
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>

      {isOpenGalleryFullView && (
        <GalleryFullView
          isOpen={isOpenGalleryFullView}
          closeGalleryView={closeGalleryView}
          images={itemsGallery}
          selectedImageForClick={selectedImageForClick}
        />
      )}
    </div>
  ) : (
    <GallerySkeleton loading images={images} />
  )
}
