export type SellerDataProps = {
  title: string
  description: string
}

export function SellerData({ title, description }: SellerDataProps) {
  return (
    <div className="flex flex-col mt-3 ">
      <span
        data-testid="title-seller-info"
        className="mb-[2px] text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3"
      >
        {title}
      </span>
      <span
        data-testid="description-seller-info"
        className="text-restructure-secondary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3"
      >
        {description}
      </span>
    </div>
  )
}
