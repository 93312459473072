import Icon from 'src/components/ui/Icon'

import { ImageData } from '../imageData'
import type { SellerInfo } from '../../../types/seller'

interface Props {
  sellerData: SellerInfo
  border: boolean
}

export function CardData({ sellerData, border }: Props) {
  return (
    <div
      className={
        border
          ? 'mb-3 flex items-center border-b border-restructure-border-primary pb-3'
          : 'mb-6 flex items-center'
      }
      data-testid="card-data"
    >
      <ImageData urlImage={sellerData.logo} altImg={sellerData.name} />

      <div data-testid="section-info-seller">
        <div
          data-testid="seller-verified"
          className="text-restructure-success desktop-caption-regular mb-1"
        >
          <i className="inline-block align-middle mr-1 mt-[-3px]">
            <Icon
              data-testid="icon-seller-verified"
              name="VerifiedSeller"
              width={16}
              height={16}
            />
          </i>
          Vendedor verificado
        </div>

        {sellerData.name !== '' && (
          <div className="text-restructure-secondary desktop-body-semibold-text2 mb-1">
            {sellerData.name}
          </div>
        )}

        {sellerData.startTime !== '' && (
          <div className="text-restructure-tertiary desktop-caption-regular">
            {sellerData.startTime}
          </div>
        )}
      </div>
    </div>
  )
}
