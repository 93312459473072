import Icon from 'src/components/ui/Icon'
import type { SellerInfo } from 'src/components/restructure/product/types/seller'

import { CardData } from './cardData'
import { OrdersData } from './ordersData'
import { SellerData } from './sellerData'

import './styles.scss'

type ContentProps = {
  tooltip: boolean
  sellerData: SellerInfo
  closeModal: () => void
}

export function Content({ sellerData, tooltip, closeModal }: ContentProps) {
  if (tooltip) {
    return (
      <div
        className="tooltip box-seller font-roboto pr-3 pl-6 py-6 restructure-small-desktop:pr-4 bg-white w-full min-w-[300px] restructure-small-desktop:min-w-[700px] rounded-md flex flex-col justify-center items-center"
        aria-label="Modal Mais Informações"
        role="dialog"
        data-testid="section-modal-info-seller"
      >
        <div className="items-center w-full justify-between flex align-top mb-6 restructure-tablet:mb-7 pr-4 border-b-[1px] border-restructure-border-primary pb-4 restructure-small-desktop:pb-[19px]">
          <span className="desktop-body-regular-text1 text-restructure-primary">
            Informações sobre o vendedor
          </span>

          <button id="CloseModal" title="Fechar" onClick={() => closeModal()}>
            <Icon
              data-testid="icon-close-modalsellerInfor"
              name="CloseModalSellerInfo"
              width={24}
              height={24}
            />
          </button>
        </div>

        <div
          data-testid="seller-verified"
          className="element-scroll max-h-[500px] w-full overflow-x-hidden overflow-y-auto pr-3"
        >
          <CardData sellerData={sellerData} border={false} />

          {sellerData.description !== undefined &&
            sellerData.description !== '' && (
              <div className="description-content desktop-body-regular-text2 mobile-body-regular-text3 restructure-tablet:mb-6 mb-8">
                {sellerData.description}
              </div>
            )}

          <div className="mb-6 restructure-small-desktop:mb-4">
            <OrdersData sellerData={sellerData} section="modal" />
          </div>

          {sellerData.collectionLinkWeb !== '' && (
            <a
              href={sellerData.collectionLinkWeb}
              className="mb-6 text-restructure-primary-inverted hover:bg-restructure-background-neutral-11 bg-restructure-background-primary-inverted desktop-body-semibold-text2 w-full text-center rounded-round py-3 border-0 flex item-center justify-center"
            >
              Acessar catálogo do vendedor
            </a>
          )}

          <div data-testid="card-data" className="content-sale-seller w-full">
            {sellerData.company !== '' ||
            sellerData.taxCode !== '' ||
            sellerData.address !== '' ? (
              <div className="legal-information pt-6 border-t-[1px] border-restructure-border-primary">
                <span className="text-restructure-primary desktop-body-semibold-text3 mobile-body-semibold-text3">
                  Informações legais
                </span>

                {sellerData.company !== '' && (
                  <SellerData
                    title="Razão Social"
                    description={sellerData.company}
                  />
                )}

                {sellerData.taxCode !== '' && (
                  <SellerData title="CNPJ" description={sellerData.taxCode} />
                )}

                {sellerData.address !== '' && (
                  <SellerData
                    title="Endereço"
                    description={sellerData.address}
                  />
                )}
              </div>
            ) : null}

            {sellerData.exchangeReturnPolicy !== '' ||
            sellerData.deliveryPolicy !== '' ||
            sellerData.securityPrivacyPolicy !== '' ? (
              <div className="general-purchasing-information">
                <span className="text-restructure-primary desktop-body-semibold-text3 mobile-body-semibold-text3 mt-8 block">
                  Informações gerais da compra
                </span>

                {sellerData.exchangeReturnPolicy !== '' && (
                  <SellerData
                    title=" Política de trocas e devoluções"
                    description={sellerData.exchangeReturnPolicy}
                  />
                )}

                {sellerData.deliveryPolicy !== '' && (
                  <SellerData
                    title="Política de envio"
                    description={sellerData.deliveryPolicy}
                  />
                )}

                {sellerData.securityPrivacyPolicy !== '' && (
                  <SellerData
                    title="Política de Privacidade e Segurança"
                    description={sellerData.securityPrivacyPolicy}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="w-[90%] restructure-small-desktop:max-w-[532px] restructure-small-desktop:w-[532px] pt-4 px-4 pb-7 bg-restructure-background-primary rounded-md flex flex-col justify-center items-center"
      aria-label="Modal Mais Informações"
      role="dialog"
      data-testid="modal-more-info"
    >
      <button
        id="closeModal"
        className="absolute top-0 right-0 p-4"
        title="Fechar"
        onClick={() => closeModal()}
      >
        <Icon
          data-testid="icon-close-modalsellerInfor"
          name="CloseModalSellerInfo"
          width={24}
          height={24}
        />
      </button>
      <h3 className="text-restructure-secondary mobile-heading-title3 tablet-heading-title3 desktop-heading-title4 mt-6 ">
        Mais ofertas para você
      </h3>
      <p className="max-w-[467px] text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3 mt-4 mx-auto mb-0 text-center">
        A Decathlon em parceria com outras marcas e vendedores, oferece o mesmo
        produto vendido e entregue por empresas parceiras.
        <br />
        <br />
        Mais variações de preços, tamanhos e cores para a sua compra.Compare e
        compre sempre com a melhor condição. 💙
      </p>
    </div>
  )
}
