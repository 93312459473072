import { useContext, useState } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { formatterPrice } from 'src/utils/formatterPrice'

import { TableInstallment } from './TableInstallment'

export function Installment() {
  const { currentSku } = useContext(ProductContext)
  const {
    sellerDefault: {
      sellerOffer: { installments, bestInstallment: bestInstallmentDefault },
    },
  } = currentSku

  const [tableOpen, setTableOpen] = useState(false)

  const notDecathlonCardInstallments = installments.filter(
    ({ onlyDecathlonCard }) => !onlyDecathlonCard
  )

  const bestInstallment = bestInstallmentDefault?.onlyDecathlonCard
    ? notDecathlonCardInstallments.pop()
    : bestInstallmentDefault

  return (
    <>
      {installments.length > 0 && bestInstallment && (
        <div className="relative mt-1 w-full max-w-[245px]">
          <button
            onClick={() => setTableOpen(!tableOpen)}
            className="underline desktop-caption-regular text-restructure-tertiary"
            data-testid="buttonOpenTableList"
          >
            {`${bestInstallment.NumberOfInstallments}x ${formatterPrice(
              bestInstallment.Value
            )}`}
          </button>
          {tableOpen && <TableInstallment />}
        </div>
      )}
    </>
  )
}
