import { useMobile } from 'src/hooks/useMobile'
import Skeleton from 'react-loading-skeleton'
import type { PropsWithChildren } from 'react'
import type { GallerySkeletonProps } from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'

import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

export const GallerySkeleton = (
  { images }: GallerySkeletonProps,
  { children, loading }: PropsWithChildren<Props>
) => {
  const { screenWidth } = useMobile()

  return loading ? (
    <div>
      {screenWidth > 900 ? (
        <div
          data-testid="gallery-skeleton-grid"
          className="grid grid-cols-6 gap-4"
        >
          {images.slice(0, 7).map((image, index: number) => (
            <div
              key={image.imageUrl}
              className={index < 4 ? 'col-span-3' : 'col-span-2'}
            >
              <div className="w-full">
                <Skeleton height={index < 4 ? 411 : 266} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div data-testid="gallery-skeleton-fullwidth" className="w-full">
          <Skeleton height={328} />
        </div>
      )}
    </div>
  ) : (
    <div data-testid="gallery-skeleton-loaded">{children}</div>
  )
}
