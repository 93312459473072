import Icon from 'src/components/ui/Icon'
import type { SellerInfo } from 'src/components/restructure/product/types/seller'

export type OrdersDataProps = {
  sellerData: SellerInfo
  section: string
}

export function OrdersData({ sellerData, section }: OrdersDataProps) {
  if (sellerData.quantityProducts !== '') {
    return (
      <div
        className={
          section === 'fixed'
            ? 'flex flex-col'
            : 'flex restructure-tablet:flex-row flex-col items-center justify-between'
        }
      >
        {sellerData.resumeSales !== '' &&
          sellerData.resumeSalesDescription !== '' && (
            <span
              className={
                section === 'fixed'
                  ? 'flex text-restructure-tertiary mobile-caption-regular tablet-caption-regular desktop-caption-regular mb-2'
                  : 'restructure-tablet:w-[49%] w-full text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3 flex items-center text-center justify-center rounded border-restructure-border-primary border-[1px] py-2.5 mb-2 restructure-tablet:mb-0'
              }
            >
              {section === 'fixed' ? (
                <>
                  <Icon
                    data-testid="icon-resume-sales"
                    name="ResumeSalesFixed"
                    width={16}
                    height={16}
                  />
                  &nbsp;{sellerData.resumeSales}{' '}
                  {sellerData.resumeSalesDescription}
                </>
              ) : (
                <>
                  <Icon
                    data-testid="icon-resume-sales"
                    name="ResumeSales"
                    width={24}
                    height={24}
                  />
                  &nbsp;<strong>{sellerData.resumeSales}</strong>&nbsp;
                  {sellerData.resumeSalesDescription}
                </>
              )}
            </span>
          )}

        <span
          className={
            section === 'fixed'
              ? 'flex text-restructure-tertiary mobile-caption-regular tablet-caption-regular desktop-caption-regular'
              : 'restructure-tablet:w-[49%] w-full text-restructure-tertiary mobile-body-regular-text3 tablet-body-regular-text3 desktop-body-regular-text3 flex items-center text-center justify-center rounded border-restructure-border-primary border-[1px] py-2.5'
          }
        >
          {section === 'fixed' ? (
            <>
              <Icon
                data-testid="icon-products-catalog"
                name="ProductsCatalogFixed"
                width={16}
                height={16}
              />
              <>&nbsp;{sellerData.quantityProducts} produtos em catálogo</>
            </>
          ) : (
            <>
              <Icon
                data-testid="icon-products-catalog"
                name="ProductsCatalog"
                width={24}
                height={24}
              />
              &nbsp;
              <strong>{sellerData.quantityProducts} produtos&nbsp;</strong>em
              catálogo
            </>
          )}
        </span>
      </div>
    )
  }

  return (
    <div className=" text-deepGray text-xs">
      <span>Saiba mais sobre o vendedor</span>
    </div>
  )
}
