import { useMobile } from 'src/hooks/useMobile'
import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'

import { GalleryImageDesktop } from './GalleryDesktop'
import { GalleryImageMobile } from './GalleryMobile'

export const GalleryImage = () => {
  const { screenWidth } = useMobile()
  const { currentSku, product } = useContext(ProductContext)

  const { images } = currentSku

  const video = product?.champion?.components?.videoGallery?.videos?.[0]

  return (
    <>
      {images && (
        <>
          {screenWidth <= 900 ? (
            <GalleryImageMobile images={images} video={video} />
          ) : (
            <GalleryImageDesktop images={images} video={video} />
          )}
        </>
      )}
    </>
  )
}
