import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
  children?: ReactNode
}
export default function Portal({ children }: PortalProps) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  const portalRoot = document.querySelector(`#portal`)

  if (!portalRoot) {
    throw new Error("The element #portal wasn't found")
  }

  return ReactDOM.createPortal(children, portalRoot)
}
