import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { formatterPrice } from 'src/utils/formatterPrice'

export function TableInstallment() {
  const { currentSku } = useContext(ProductContext)
  const {
    sellerDefault: {
      sellerOffer: { installments },
    },
  } = currentSku

  const anyCreditCard = installments.filter(
    ({ onlyDecathlonCard }) => !onlyDecathlonCard
  )

  const decathlonCreditCard = installments.filter(
    ({ onlyDecathlonCard }) => onlyDecathlonCard
  )

  return (
    <div
      className="absolute top-6 left-0 shadow-dropCard rounded-sm z-10 w-full max-w-[245px] min-w-[140px] bg-white "
      data-testid="modalTableInstallment"
    >
      <ul className="flex flex-col gap-xs p-sm">
        {anyCreditCard.map((installment) => (
          <li
            key={installment.NumberOfInstallments}
            className="desktop-body-regular-text3 text-restructure-secondary"
          >{`${installment.NumberOfInstallments}x ${formatterPrice(
            installment.Value
          )}`}</li>
        ))}
      </ul>

      {decathlonCreditCard?.length > 0 && (
        <div className="p-sm bg-restructure-background-secondary">
          <p className="desktop-body-semibold-text3 text-restructure-action">
            Com o cartão Decathlon
          </p>
          <ul className="flex flex-col gap-xs mt-xs">
            {decathlonCreditCard.map((installment) => (
              <li
                key={installment.NumberOfInstallments}
                className="desktop-body-regular-text3 text-restructure-secondary"
              >
                {`${installment.NumberOfInstallments}x ${formatterPrice(
                  installment.Value
                )}`}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
