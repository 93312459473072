import { ProductTitle } from './ProductTitle'
import { ProductPricing } from './ProductPricing'
import { SellerInformation } from './SellerInformation'
import { SkuSelector } from './SkuSelector'
import { ProductAction } from './ProductAction'

export function Sidebar() {
  return (
    <>
      <ProductTitle />
      <ProductPricing />
      <SellerInformation />
      <SkuSelector />
      <ProductAction />
    </>
  )
}
