import './modal.scss'

type ModalProps = {
  title?: string
  message: string
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

function Modal({
  title = 'Aviso!',
  openModal = false,
  message,
  setOpenModal,
}: ModalProps) {
  return (
    <>
      {openModal && (
        <div className="modal">
          <button
            className="modal__overlay"
            onClick={() => setOpenModal(false)}
          />
          <div className="modal__content">
            <div className="modal__header">
              <span className="modal__title">{title}</span>
              <button
                className="modal__close"
                onClick={() => setOpenModal(false)}
              >
                X
              </button>
            </div>
            <div className="modal__message">
              <p>{message}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
