import { Link } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import { useCheckFavoriteProducts } from 'src/sdk/product/useWishlist'

import WishlistIcon from './WishlistIcon'

import './product-card-recommendation.scss'

type ReferenceProduct = {
  productId: string
  productName: string
  link: string
  items: Array<{
    offerDefault: {
      price: number
    }
    images: Array<{
      imageUrl: string
    }>
    sellers: Array<{
      commertialOffer: {
        IsAvailable: boolean
      }
    }>
  }>
}

type RecommendationProps = {
  product: ReferenceProduct
}

const ProductCardRecommendation = ({ product }: RecommendationProps) => {
  const { userData } = useUserData()

  const isInWishlist = useCheckFavoriteProducts(
    userData.UserId ?? 'undefineduser',
    product?.productId
  )

  const formatPrice = (price: number) => {
    if (!price) {
      return { integer: 0, cents: 0 }
    }

    const [integer, cents] = String(price.toFixed(2)).split('.')

    return { integer, cents }
  }

  const productItem = product.items?.find((item) =>
    item.sellers.find((seller) => seller.commertialOffer.IsAvailable)
  )

  const imageDefault = productItem?.images?.[0].imageUrl

  return (
    <div className="product-card-recommendation pb-3 px-2">
      <div className="product-card-recommendation__image-container relative">
        <WishlistIcon
          user={userData}
          fillWishlist={isInWishlist}
          prodId={product?.productId}
        />
        <Link
          className="product-card-recommendation__image-container--image"
          to={`https:${product?.link}`}
        >
          <img
            src={imageDefault}
            alt={product?.productName}
            width={168}
            height={143}
            className="mx-auto"
          />
        </Link>
      </div>
      <div className="product-card-recommendation__name text-[10px] h-8 mt-4 mb-10">
        <Link to={`https:${product?.link}`}>{product?.productName}</Link>
      </div>
      <div className="product-card-recommendation__price">
        <div className="product-card-recommendation__price--mainPrice font-bold py-2 px-1 bg-[#FFEA28] w-fit mb-1">
          <span className="text-xs align-super">R$</span>
          <span className="text-2xl">{`${
            formatPrice(productItem?.offerDefault?.price ?? 0)?.integer
          }`}</span>
          ,
          <span className="text-sm">{`${
            formatPrice(productItem?.offerDefault?.price ?? 0)?.cents
          }`}</span>
        </div>
      </div>
    </div>
  )
}

export default ProductCardRecommendation
