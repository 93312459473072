import { navigate } from 'gatsby'
import type { UserData } from 'src/components/contexts/UserDataContext'
import { toggleWishList } from 'src/sdk/product/useWishlist'

import { loginUrl } from '../../../../../store.config'

type WishlistIconProps = {
  user: UserData
  fillWishlist: boolean
  prodId: string
}

const WishlistIcon = ({ user, fillWishlist, prodId }: WishlistIconProps) => {
  return (
    <span
      className={`wishlist-ico absolute z-30 favorite-button w-[20px] h-[20px] self-end my-auto ml-auto cursor-pointer ${
        fillWishlist ? 'favorite-button-fill' : ''
      }
      }`}
      onClick={(e) =>
        user.UserId
          ? toggleWishList({ id: user.UserId, itemId: prodId, seller: '1', e })
          : navigate(loginUrl)
      }
      aria-hidden="true"
    />
  )
}

export default WishlistIcon
