import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'

export function SellerInformation() {
  const { currentSku } = useContext(ProductContext)
  const { sellerDefault, manufacturerCode } = currentSku

  return (
    <div className="flex items-center justify-between mt-3 pb-3">
      <p
        className="flex items-center desktop-caption-regular text-restructure-tertiary"
        data-testid="sellerNameText"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          data-testid="svg-icon"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.82428 0.865332C7.93747 0.822884 8.06221 0.822884 8.1754 0.865332L13.5087 2.86533C13.7039 2.93851 13.8332 3.12507 13.8332 3.3335V8.00016C13.8332 10.2234 12.359 12.017 10.9958 13.2098C10.3036 13.8154 9.6139 14.2893 9.09817 14.6117C8.83978 14.7732 8.62375 14.8974 8.47116 14.9819C8.39484 15.0241 8.33429 15.0565 8.29215 15.0786C8.27108 15.0897 8.2546 15.0982 8.24304 15.1041L8.22943 15.111L8.22547 15.113L8.22421 15.1137C8.22404 15.1137 8.22344 15.114 7.99984 14.6668C7.77623 15.114 7.77608 15.114 7.77591 15.1139L7.7742 15.113L7.77024 15.111L7.75663 15.1041C7.74508 15.0982 7.7286 15.0897 7.70752 15.0786C7.66538 15.0565 7.60484 15.0241 7.52851 14.9819C7.37593 14.8974 7.15989 14.7732 6.9015 14.6117C6.38577 14.2893 5.69603 13.8154 5.00392 13.2098C3.64071 12.017 2.1665 10.2234 2.1665 8.00016V3.3335C2.1665 3.12507 2.29579 2.93851 2.49094 2.86533L7.82428 0.865332ZM7.99984 14.6668L7.77591 15.1139C7.91667 15.1843 8.08268 15.1844 8.22344 15.114L7.99984 14.6668ZM7.99984 14.0998C8.13829 14.0229 8.33398 13.91 8.56817 13.7637C9.05244 13.461 9.69603 13.0183 10.3373 12.4572C11.6407 11.3167 12.8332 9.77694 12.8332 8.00016V3.68L7.99984 1.8675L3.1665 3.68V8.00016C3.1665 9.77694 4.35896 11.3167 5.66242 12.4572C6.30365 13.0183 6.94723 13.461 7.4315 13.7637C7.66569 13.91 7.86138 14.0229 7.99984 14.0998Z"
            fill="#687787"
          />
        </svg>
        <span className="ml-xxs mr-xxxs">Vendido e entregue por</span>
        <strong className="desktop-caption-semibold">
          {sellerDefault.sellerName}
        </strong>
      </p>
      <p className="desktop-caption-regular text-restructure-tertiary">
        REF: {manufacturerCode}
      </p>
    </div>
  )
}
