import { Splide, SplideSlide } from '@splidejs/react-splide'
import { ModalCommercialsBenefits } from 'src/components/sections/ProductDetails/ModalCommercialsBenefits'
import { useState } from 'react'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import type { CommercialBenefit } from 'src/components/product-page/context/types/product.type'

import { useProductPageContext } from '../../contexts'
import { BenefitsItem } from './BenefitsItem'

export function CommercialBenefits() {
  const {
    product: { commercialsBenefits },
  } = useProductPageContext()

  const [isOpen, setIsOpen] = useState(false)
  const [dataModal, setDataModal] = useState(commercialsBenefits[0])

  const handleOnClick = (commercialBenefit: CommercialBenefit) => {
    setDataModal(commercialBenefit)
    setIsOpen(true)

    const event = makeEventTrack({
      eventAction: 'saiba mais',
      eventPage: 'pagina de produto',
    })

    sendEvent(event)
  }

  return (
    <div className="restructure-tablet:py-sm restructure-tablet:px-md mt-lg mb-lg restructure-tablet:border restructure-tablet:border-restructure-border-secondary restructure-tablet:rounded-md restructure-tablet:bg-restructure-background-primary">
      {commercialsBenefits.length > 1 ? (
        <Splide
          options={{
            perPage: 1,
            arrows: false,
            pagination: false,
            type: commercialsBenefits.length > 1 ? 'loop' : 'slide',
            direction: 'ttb',
            heightRatio: 0.14,
            height: 50,
            cover: false,
            rewind: false,
            autoplay: true,
            interval: 5000,
          }}
        >
          {commercialsBenefits.map((item) => (
            <SplideSlide
              key={item.text}
              className="flex items-start gap-xs items-center"
            >
              <BenefitsItem
                item={item}
                onClickButton={(e) => {
                  handleOnClick(e)
                }}
              />
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <div className="flex items-start gap-xs ">
          <BenefitsItem
            item={commercialsBenefits?.[0]}
            onClickButton={(e) => {
              handleOnClick(e)
            }}
          />
        </div>
      )}
      <ModalCommercialsBenefits
        handleCloseCommercialsBenefitsModal={setIsOpen}
        openModalCommercialsBenefits={isOpen}
        comercialBenefitsData={dataModal}
      />
    </div>
  )
}
