import { useEffect } from 'react'
import type { ReactNode } from 'react'
import classNames from 'classnames'

import Portal from './Portal'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  className?: string
  opacityTailwind?: string
  dataTestid?: string
}

export function Modal({
  isOpen,
  onClose,
  className,
  children,
  opacityTailwind = 'opacity-80',
  dataTestid,
}: ModalProps) {
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    })
  }, [onClose])

  if (!isOpen) {
    return null
  }

  return (
    <>
      <Portal>
        <div
          data-testid={dataTestid}
          aria-hidden="true"
          onClick={() => onClose()}
          className={`modal__background fixed t-0 z-[10002] inset-0 h-screen w-screen bg-restructure-background-primary-inverted ${opacityTailwind}`}
        />
        <div
          className={classNames(
            'modal__container fixed z-[10003] m-auto justify-center items-center bottom-0 left-0 restructure-small-desktop:bottom-auto restructure-small-desktop:top-1/2 restructure-small-desktop:left-1/2 restructure-small-desktop:translate-x-[-50%] restructure-small-desktop:translate-y-[-50%] rest w-full shadow-1 bg-restructure-background-primary',
            className
          )}
          data-testid="modalContainer"
        >
          {children}
        </div>
      </Portal>
    </>
  )
}
