import { useState } from 'react'
import { Image } from 'src/components/ui/Image'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import type {
  GalleryImageDesktopProps,
  Video,
  Image as TImage,
} from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'
import Icon from 'src/components/ui/Icon'

import { GalleryFullView } from '../GalleryFullView'
import { GallerySkeleton } from '../GallerySkeleton'
import { GalleryMovie } from '../GalleryMovie'
import { formatSrc } from '../../../../utils/formatSrc'
import { GalleryFlags } from '../GalleryFlags'

export const GalleryImageDesktop = ({
  images,
  video,
}: GalleryImageDesktopProps) => {
  const [isOpenGalleryFullView, setIsOpenGalleryFullView] = useState(false)
  const [selectedImageForClick, setSelectedImageForClick] = useState<TImage>(
    images[0]
  )

  const openGalleryView = (image: TImage, event: { target: any }) => {
    setIsOpenGalleryFullView(true)
    setSelectedImageForClick(image)

    const eventTrack = makeEventTrack({
      eventAction: event.target.ariaLabel,
      eventPage: 'pagina de produto',
    })

    sendEvent(eventTrack)
  }

  const closeGalleryView = () => {
    setIsOpenGalleryFullView(false)
  }

  const itemsGallery: Array<Video | TImage> = video
    ? [...images.slice(0, 1), video, ...images.slice(1)]
    : images

  return (
    <section className="pb-[64px]">
      {itemsGallery.length ? (
        <div className="relative">
          <GalleryFlags />
          <div className="grid grid-cols-6 gap-4">
            {itemsGallery.slice(0, 7).map((item, index) => (
              <div
                key={index}
                className={`bg-restructure-background-secondary mix-blend-multiply rounded-md ${
                  index < 4 ? 'col-span-3' : 'col-span-2'
                }`}
              >
                {'url' in item ? (
                  <GalleryMovie {...item} />
                ) : (
                  <Image
                    className="h-full object-cover rounded-md hover:cursor-zoom-in bg-restructure-background-secondary mix-blend-multiply"
                    key={item.imageUrl}
                    src={item.imageUrl}
                    srcSet={formatSrc(item.imageUrl, 628, 628)}
                    width={628}
                    height={628}
                    alt={item.imageText}
                    loading="eager"
                    preload
                    data-testid="image-gallery"
                    aria-label="Imagem da galeria"
                    onClick={(event) => openGalleryView(item, event)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <GallerySkeleton loading images={images} />
      )}

      {itemsGallery.length > 7 && (
        <div className="w-[266px] flex mx-auto mt-4">
          <button
            className="desktop-body-semibold-text2 border-sm rounded-round py-sm outline-none flex w-full items-center justify-center border-restructure-border-primary hover:bg-restructure-background-primary-inverted hover:bg-opacity-5 active:bg-restructure-background-primary-inverted active:bg-opacity-10"
            onClick={(event) =>
              openGalleryView(itemsGallery[0] as TImage, event)
            }
            data-testid="button-more-pictures"
            aria-label="Ver mais fotos"
            value="Ver mais fotos"
          >
            Ver mais fotos
            <Icon
              className="ml-2"
              aria-label="Botão para exibir a galeria de imagem"
              data-testid="plusIcon"
              name="PlusIcon"
              width={20}
              height={21}
            />
          </button>
        </div>
      )}

      {isOpenGalleryFullView && (
        <GalleryFullView
          isOpen={isOpenGalleryFullView}
          closeGalleryView={closeGalleryView}
          images={itemsGallery}
          selectedImageForClick={selectedImageForClick}
        />
      )}
    </section>
  )
}
