interface DescriptionProps {
  description: string
}

export function Description({ description }: DescriptionProps) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: description }}
      className="desktop-body-regular-text3 text-restructure-tertiary"
    />
  )
}
