import { Image } from 'src/components/ui/Image'
import type { Video } from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'

export const GalleryMovie = (props: Video) => {
  const { url, videoType } = props

  const formatUrl = (sourceVideo: string) => {
    const replaceHost = sourceVideo.replace('youtu.be', 'youtube.com/embed')
    const addSettingsToPlayer = replaceHost.concat(
      `${videoType === 'youtube' ? '?' : '&'}autoplay=1&mute=1&loop=1`
    )

    return addSettingsToPlayer
  }

  return (
    <div className="flex items-center h-full w-full relative">
      <div id="draggable-iframe" className=" h-[85%] w-full absolute top-0" />
      <iframe
        className="w-full h-full rounded-md"
        src={formatUrl(url)}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        data-testid="gallery-item-movie"
      />
    </div>
  )
}

export const GalleryMovieThumb = (props: Video) => {
  const { thumbnail, label } = props

  return (
    <Image
      src={
        thumbnail ??
        'https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/8b3268dc-70dd-4ef7-a2d0-1b9b879cd88c___473ae1e328085f4bb470f9f85d393465.png'
      }
      width={88}
      height={88}
      alt={label}
      style={{ objectFit: 'contain' }}
      loading="eager"
      preload
      className="w-[88px] bg-restructure-background-secondary mix-blend-multiply rounded-md"
      aria-label="Miniatura do video no carousel de thumbs das imagens"
    />
  )
}
