import { useState } from 'react'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import { StarsIcon } from 'src/components/product/CustomerReviews/Icons/StarsIcon'

import { Modal } from '../../utils/Modal'

type Notes = {
  count: number
  countRecommended: number
  key: number
}

type ResumerProps = {
  totalReviews?: number
  ratingNote?: number
  productCode: string
  notes: Notes[]
}

const notesDefault = [
  { count: 0, countRecommended: 0, key: 5 },
  { count: 0, countRecommended: 0, key: 4 },
  { count: 0, countRecommended: 0, key: 3 },
  { count: 0, countRecommended: 0, key: 2 },
  { count: 0, countRecommended: 0, key: 1 },
]

function sortNotesResumer(notes: Notes[]) {
  const list = Array.from([...notes])

  list.sort((a, b) => b!.key - a!.key)

  return list
}

export function Resumer({
  notes = notesDefault,
  ratingNote = 0,
  totalReviews = 0,
  productCode = '0',
}: ResumerProps) {
  const [isOpenForm, setIsOpenForm] = useState(false)

  const sortNotes = sortNotesResumer(notes)

  const reviewEventTrack = makeEventTrack({
    eventAction: 'avaliar produto',
    eventPage: 'pagina de produto',
  })

  return totalReviews < 1 ? (
    <div
      data-testid="resumerCardFirstReviewer"
      className="bg-restructure-background-primary py-xl restructure-tablet:py-16 px-lg rounded-lg flex flex-col items-center w-full justify-center my-xl restructure-tablet:mt-16 restructure-tablet:mb-20"
    >
      <p
        aria-label="Mensagem, seja o primeiro a avaliar este produto"
        className="mobile-heading-title-4 desktop-heading-title4 text-restructure-secondary text-center mt-0"
      >
        Seja o primeiro a avaliar este produto
      </p>
      <button
        aria-label="Botão para avaliar produto"
        onClick={() => {
          setIsOpenForm(true)
          sendEvent(reviewEventTrack)
        }}
        className="text-restructure-primary-inverted
        bg-restructure-background-primary-inverted
        text-center justify-center rounded-[360px] flex flex-row mt-8 py-3 px-20
        self-center"
      >
        Avaliar produto
      </button>

      <Modal
        aria-label="Janela de aviso que o navegador não suporta iframes"
        isOpen={isOpenForm}
        onClose={() => setIsOpenForm(false)}
        positionContent="center"
        className="w-full max-w-[95%] restructure-small-desktop:max-w-xl h-[90vh]"
      >
        <div className="w-full h-full py-6">
          <iframe
            title="Form New Review Product"
            src={`https://reviews.decathlon.com/pt_BR/review/new/dktbr/product/${productCode}`}
            className="h-full w-full"
          >
            SEU NAVEGADOR NÃO SUPORTA IFRAMES
          </iframe>
        </div>
      </Modal>
    </div>
  ) : (
    <section
      data-testid="resumerCard"
      className="bg-restructure-background-primary h-fit rounded-2xl px-md py-lg min-w-[290px] max-w-[411px]"
    >
      <div className="flex items-center justify-center gap-3">
        <StarsIcon height={20} width={20} />
        <span
          aria-label={`Média geral das avaliações em estrelas ${ratingNote?.toFixed(
            1
          )} de 5`}
          className="desktop-heading-title3 text-restructure-primary"
        >
          <span>{ratingNote?.toFixed(1)}</span>
          <span className="desktop-heading-title5 text-restructure-tertiary">
            /5
          </span>
        </span>
      </div>

      <p
        aria-label={`Total de avaliações, ${totalReviews}`}
        className="desktop-body-regular-text3 text-center text-restructure-secondary py-4"
      >
        {totalReviews} avaliações
      </p>

      <Modal
        aria-label="Janela de aviso que o navegador não suporta iframes"
        isOpen={isOpenForm}
        onClose={() => setIsOpenForm(false)}
        positionContent="center"
        className="w-full max-w-[95%] restructure-small-desktop:max-w-xl h-[90vh]"
      >
        <div className="w-full h-full py-6">
          <iframe
            title="Form New Review Product"
            src={`https://reviews.decathlon.com/pt_BR/review/new/dktbr/product/${productCode}`}
            className="h-full w-full"
          >
            SEU NAVEGADOR NÃO SUPORTE IFRAMES
          </iframe>
        </div>
      </Modal>

      <div>
        <ul className="flex flex-col gap-y-4">
          {sortNotes?.map((note) => (
            <li
              aria-label="Lista de notas em estrelas com valores de 1 a 5"
              key={note?.key}
              className="flex items-center gap-2"
            >
              <p
                aria-label={`Estrelas ${note?.key}`}
                className="desktop-body-semibold-text2 flex items-center mr-2 text-restructure-secondary gap-2"
              >
                <StarsIcon fill="#3E4751" height={16} width={16} />
                {note?.key}
              </p>

              <div className="flex-1 relative bg-offWhite h-0.5 rounded-full">
                <div
                  style={{
                    width: `
                        ${Number(
                          note?.count === 0
                            ? 0
                            : (note!.count / totalReviews) * 100
                        )}%`,
                  }}
                  className="bg-restructure-background-primary-inverted absolute top-0 left-0 h-full rounded-full"
                />
              </div>

              <p
                aria-label={`Total de estrelas para a nota ${note?.key}, ${note?.count}`}
                className="ml-2"
              >
                {note?.count}{' '}
              </p>
            </li>
          ))}
        </ul>
      </div>

      <button
        aria-label="Botão para avaliar produto"
        onClick={() => {
          setIsOpenForm(true)
          sendEvent(reviewEventTrack)
        }}
        className="text-restructure-primary-inverted
        bg-restructure-background-primary-inverted
        text-center justify-center rounded-[360px] flex mt-8 py-2 px-4 w-full"
      >
        Avaliar produto
      </button>
    </section>
  )
}
