import type { ActiveItem, Product, Items } from './types'

export function generateNewActiveItem(
  setedItem: ActiveItem,
  currentItem: Product
) {
  const currentItemSku = currentItem.items.find(
    (item: Items) => item.itemId === setedItem.itemId
  )

  const offerObject = {
    ...currentItemSku?.offerDefault,
    availability:
      setedItem.quantity > 0
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    listPrice: setedItem.listedPrice,
    price: setedItem.price,
    quantity: setedItem.quantity,
    seller: {
      identifier: setedItem.sellerId,
    },
  }

  const offers = {
    ...currentItemSku?.offerDefault,
    lowPrice: setedItem.price,
    offers: [offerObject],
  }

  return {
    ...currentItem,
    productID: setedItem.itemId,
    sku: setedItem.itemId,
    name: setedItem.name,
    offers,
    // slug: currentItem.slug.replace(currentItem.productID, setedItem.itemId),
  }
}
