export type ImageDataProps = {
  urlImage: string
  altImg: string
}

export function ImageData({ urlImage, altImg }: ImageDataProps) {
  const regex = /\b(jpe?g|png|svg)\b/gi
  const inputString = urlImage
  const matches = inputString.match(regex)

  if (matches?.length) {
    return (
      <div className="mr-4 max-w-[82px]">
        <div className="container">
          <img
            src={inputString}
            alt={altImg}
            className="rounded-md h-auto max-w-[72px]"
            data-testid="image-seller"
          />
        </div>
      </div>
    )
  }

  return null
}
