import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import type { Variation } from 'src/components/product-page/Selector/types'
import classNames from 'classnames'
import { useContext } from 'react'

interface Props {
  variation: Variation
  onClickVariation: (name: any, value: string) => void
}

export function DefaultSelector({ variation, onClickVariation }: Props) {
  const {
    currentSku: {
      sellerDefault: { sellerId },
    },
  } = useContext(ProductContext)

  const decathlonSellerId = '1'
  const sellerIsDecathlon = sellerId === decathlonSellerId

  const variationName = variation.field.name

  const sortedVariationValues = [...variation.values].sort(
    (a, b) => a.position - b.position
  )

  const keywordsHiddenComponent = [
    'ÚNICO',
    'UNICO',
    'Único',
    'ÚNICA',
    'UNICA',
    'Única',
    'U',
    ',',
    '.',
    'B',
    'N/A',
    'UN',
    'variado',
    'Sem sabor',
    'SEM-SABOR',
    '3953',
    '3954',
  ]

  if (
    sortedVariationValues.length <= 1 &&
    keywordsHiddenComponent.includes(sortedVariationValues[0].name)
  ) {
    return null
  }

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <span className="desktop-body-semibold-text3 text-restructure-secondary ">
          {variationName}:
        </span>

        {variationName === 'Tamanho' && sellerIsDecathlon && (
          <a
            href="/guia-de-tamanhos"
            target="_blank"
            className="desktop-caption-regular text-restructure-tertiary underline cursor-pointer flex items-center gap-1"
          >
            Tabela de tamanhos
          </a>
        )}
      </div>

      <ul
        className="flex items-center flex-wrap gap-sm mt-md"
        key={variation.field.id}
        data-testid="default-selector"
      >
        {sortedVariationValues.map((variationValue, index) => (
          <li key={variationValue.id}>
            <button
              disabled={!variationValue.isAvailable}
              className={classNames(
                'relative rounded-sm p-1 px-4 py-2 border border-restructure-border-secondary desktop-body-regular-text2 text-restructure-secondary hover:border-restructure-border-action hover:text-restructure-action',
                {
                  '!border-restructure-border-action border !text-restructure-action':
                    variationValue.selected,
                },
                {
                  '!border-restructure-border-disabled !bg-restructure-background-disabled !text-restructure-disabled hover:border-restructure-border-disabled hover:text-restructure-disabled  cursor-not-allowed':
                    !variationValue.isAvailable,
                }
              )}
              onClick={() =>
                onClickVariation(variationName, variationValue.name)
              }
              data-testid={`variant-size-${index}`}
            >
              {variationValue.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
