import { memo, useCallback, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import axios from 'axios'
import { Modal } from 'src/components/restructure/common/Modal'
import { Spinner } from 'src/components/common/Spinner'

import type { ReviewItemList } from '../../types/review'
import { ReviewsList } from './ReviewsList'
import { useProductPageContext } from '../../contexts'

import './styles.scss'

export interface IModalReviews {
  isOpen: boolean
  setShowModalAllReviews: React.Dispatch<React.SetStateAction<boolean>>
  reviewList: ReviewItemList[]
}

function ModalReviews({
  isOpen,
  setShowModalAllReviews,
  reviewList,
}: IModalReviews) {
  const {
    product: { productId },
  } = useProductPageContext()

  const [reviews, setReview] = useState(reviewList)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const handleGetMoreReviews = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await axios.post('/api/pdp/review', {
        page: currentPage + 1,
        productId,
      })

      setLoading(false)

      setReview((current) => [
        ...current,
        ...data?.DktPdp_GetProductReview?.reviews,
      ])
      setCurrentPage(currentPage + 1)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }, [productId, currentPage])

  return (
    <Modal
      dataTestid="allReviews"
      isOpen={isOpen}
      onClose={() => {
        document?.firstElementChild?.classList.remove('no-scroll')
        setShowModalAllReviews(false)
      }}
      className="w-full flex flex-col restructure-small-desktop:rounded-none restructure-tablet:h-[100vh] restructure-tablet:right-0 restructure-tablet:left-auto restructure-small-desktop:left-auto restructure-small-desktop:!translate-x-0 restructure-tablet:translate-x-0 restructure-small-desktop:max-w-[491px] restructure-tablet:max-w-[512px]  h-[80vh]"
      opacityTailwind="opacity-80"
    >
      <header
        aria-label="Título, todas as avaliações"
        className="desktop-body-regular-text1 w-full p-6 flex flex-row justify-between border-b border-restructure-border-primary"
      >
        Todas as avaliações
        <button
          aria-label="Botão para fechar a sessão de ver todas as avaliações"
          className="cursor-pointer"
          data-testid="buttonCloseModalAllReviews"
          onClick={() => {
            document?.firstElementChild?.classList.remove('no-scroll')
            setShowModalAllReviews(false)
          }}
        >
          <Icon name="Close" width={24} height={24} fill="#15181B" />
        </button>
      </header>
      <div className="w-full overflow-y-scroll flex-1 mr-2 p-lg review-list">
        <ReviewsList reviewList={reviews} />
      </div>
      <footer className="mt-auto mb-0 px-lg pb-xl w-full">
        <button
          onClick={() => handleGetMoreReviews()}
          aria-label="Botão para carregar mais avaliações"
          className="desktop-body-semibold-text2 flex flex-row items-center text-center
            rounded-[360px] justify-center
            mt-4 py-4 px-4 w-full
            border border-restructure-border-primary gap-2"
        >
          {loading ? (
            <>
              Carregando
              <Spinner color="#15181B" />
            </>
          ) : (
            'Carregar mais'
          )}
        </button>
      </footer>
    </Modal>
  )
}

export default memo(ModalReviews)
