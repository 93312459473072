import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import type { Flag } from 'src/components/restructure/product/types/productItem'

export const GalleryFlags = () => {
  const { flags } = useContext(ProductContext).currentSku

  if (!flags.length) return null

  return (
    <div
      data-testid="gallery-flags"
      className="absolute top-2 left-2 z-[9] flex gap-2"
    >
      {flags.slice(0, 2).map((flag: Flag, index: number) => (
        <span
          key={`${index}`}
          className="font-inter text-font-size-1 restructure-small-desktop:text-font-size-2 font-medium tracking-[0.5px] p-xxs rounded-sm uppercase restructure-small-desktop:py-[1px]"
          style={{
            background: `${flag.color}`,
            color: `${flag.textColor}`,
          }}
          data-testid={flag.value}
          aria-label={`Flag do produto: ${flag.value}`}
        >
          {flag.value}
        </span>
      ))}
    </div>
  )
}
