import { useState } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import Icon from 'src/components/ui/Icon'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import type { SellerInfo as SellerData } from 'src/components/restructure/product/types/seller'

import { Modal } from '../../utils/Modal'
import { CardData } from './cardData'
import { OrdersData } from './ordersData'
import { Content } from './Content'
import './styles.scss'

interface SellerInfoProps {
  sellerData?: SellerData | null
}

export function SellerInfo({ sellerData }: SellerInfoProps) {
  const [tooltip, setTooltip] = useState(false)
  const [tooltipMoreOffers, setTooltipMoreOffers] = useState(false)
  const { screenWidth } = useMobile()

  const handleClickTooltip = (event: { target: any }) => {
    setTooltip(true)

    const eventTrack = makeEventTrack({
      eventAction: `${event.target.ariaLabel} - informações do vendedor`,
      eventPage: 'pagina de produto',
    })

    sendEvent(eventTrack)
  }

  return (
    <>
      {sellerData && (
        <div>
          <div
            data-testid="seller-info-fixed"
            className="rounded-lg font-roboto mt-6 pt-3 px-4 pb-4 border border-gray border-solid w-[90%] m-auto restructure-small-desktop:w-full restructure-small-desktop:m-0"
          >
            <div className="top-content-seller flex justify-between mb-3">
              <span className="text-restructure-tertiary desktop-caption-regular">
                Este item é vendido e entregue por:
              </span>
              <button
                onClick={() => setTooltipMoreOffers(true)}
                className="icon-info-box-seller"
                aria-label="Modal mais ofertas"
              >
                <Icon
                  data-testid="icon-more-info-modal"
                  name="MoreInfo"
                  width={16}
                  height={16}
                />
              </button>
            </div>

            <CardData sellerData={sellerData} border />

            <div className="flex justify-between items-end">
              <div className="flex flex-col">
                <OrdersData sellerData={sellerData} section="fixed" />
              </div>
              <button
                className=" text-restructure-action mobile-caption-semibold tablet-caption-semibold desktop-caption-semibold"
                onClick={(event) => handleClickTooltip(event)}
                aria-label="Ver mais"
              >
                Ver mais
                {screenWidth > 900 && (
                  <i className="inline-block align-middle ml-1 mt-[-2px]">
                    <Icon
                      data-testid="icon-more-info-dataseller"
                      name="ArrowSellerInfo"
                      width={16}
                      height={16}
                    />
                  </i>
                )}
              </button>
            </div>
          </div>

          <Modal
            isOpen={tooltip || tooltipMoreOffers}
            onClose={() => {
              setTooltip(false)
              setTooltipMoreOffers(false)
            }}
            positionContent={
              screenWidth > 900 && tooltip
                ? 'center'
                : screenWidth > 600 && tooltipMoreOffers
                ? 'center'
                : 'bottom'
            }
            className="rounded-md"
          >
            <Content
              closeModal={() => {
                setTooltip(false)
                setTooltipMoreOffers(false)
              }}
              sellerData={sellerData}
              tooltip={tooltip}
            />
          </Modal>
        </div>
      )}
    </>
  )
}
