import { StarsIcon } from 'src/components/product/CustomerReviews/Icons/StarsIcon'

type ReviewCardProps = {
  user: {
    name: string
    country: string | null
    years: string
  }
  description: string
  title?: string
  stars: number
  language?: string | null
}

export function CardReview({ description, stars, user }: ReviewCardProps) {
  return (
    <div
      data-testid="cardReview"
      className="bg-restructure-background-primary p-lg border border-restructure-border-primary rounded-lg"
    >
      <div className="flex justify-between">
        <p
          aria-label={`Nome do usuário que fez a avaliação, ${user.name}`}
          className="desktop-body-semibold-text2 text-restructure-secondary"
        >
          {user.name}
        </p>
        <p
          aria-label={`Número de estrelas que o usuário avaliou, ${stars}`}
          className="flex items-center gap-x-2"
        >
          <StarsIcon height={16} width={16} />
          <span className="desktop-body-regular-text3 text-restructure-primary">
            {stars}
          </span>
        </p>
      </div>

      <p
        aria-label={`País do usuário que fez a avaliação, ${user.country}`}
        className="desktop-caption-regular text-restructure-tertiary"
      >
        {user.years && `${user.years}, `}
        {user.country}
      </p>

      <div className="max-w-3xl mt-md">
        <div
          className="desktop-body-regular-text3 text-restructure-secondary max-w-3xl"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}
