import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import { memo, useContext, useRef } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import Icon from 'src/components/ui/Icon'
import './styles.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

function TechnicalInformation({ isOpen, onClose, onOpen }: Props) {
  const headerRef = useRef<HTMLHeadElement>(null)
  const { product } = useContext(ProductContext)
  const { technicalInformation, productDocuments } = product

  const eventTrack = makeEventTrack({
    eventAction: `informacoes tecnicas - ${!isOpen}`,
    eventPage: 'pagina de produto',
  })

  return (
    <>
      {technicalInformation && (
        <section className="bg-restructure-background-secondary  px-md py-md restructure-tablet:px-10 restructure-tablet:py-8 rounded-lg w-full max-w-[845px]">
          <header ref={headerRef}>
            <button
              data-testid="buttonCollapseTechnicalInformation"
              name="Informações Técnicas"
              className="flex justify-between items-center w-full"
              onClick={() => {
                if (isOpen) {
                  onClose()
                } else {
                  onOpen()
                  setTimeout(() => {
                    headerRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'start',
                    })
                  }, 200)
                }

                sendEvent(eventTrack)
              }}
            >
              <h3
                className="mobile-heading-title5 desktop-heading-title5 text-restructure-primary"
                aria-label="Título informações técnicas do produto"
              >
                Informações técnicas
              </h3>
              {isOpen ? (
                <Icon
                  aria-label="Botão para minimizar as informações técnicas do produto"
                  data-testid="minusIcon"
                  name="Minus"
                  width={32}
                  height={32}
                  fill="#15181B"
                />
              ) : (
                <Icon
                  aria-label="Botão para maximizar as informações técnicas do produto"
                  data-testid="plusIcon"
                  name="Plus"
                  width={32}
                  height={32}
                  fill="#15181B"
                />
              )}
            </button>
          </header>

          {isOpen && (
            <>
              {productDocuments && (
                <div
                  data-testid="productDocuments"
                  className="productManualAndDocs
                  w-full
                  restructure-tablet:max-w-[350px]
                  restructure-mobile:max-w-[296px]
                  flex flex-row bg-white items-center mt-8
                  border-solid border-[1px] border-primary rounded-lg justify-between px-6 py-4"
                >
                  <div dangerouslySetInnerHTML={{ __html: productDocuments }} />
                </div>
              )}

              {technicalInformation && (
                <div
                  data-testid="productTechnicalInformation"
                  className="product-technical-info
                  pp:gap-y-6
                  pp:grid pp:grid-cols-1
                  md:grid md:grid-cols-2
                  md:gap-y-10
                  tablet:gap-y-8
                  gap-x-10"
                  dangerouslySetInnerHTML={{
                    __html: technicalInformation,
                  }}
                />
              )}
            </>
          )}
        </section>
      )}
    </>
  )
}

export default memo(TechnicalInformation)
