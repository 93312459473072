import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { formatterPrice } from 'src/utils/formatterPrice'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

import { Installment } from './Installments'

export function ProductPricing() {
  const { currentSku } = useContext(ProductContext)
  const {
    sellerDefault: { sellerOffer },
  } = currentSku

  const isB2B = checkEnviromentIsB2B()

  return (
    <div>
      <div className="flex items-center gap-xxs mt-lg">
        {currentSku.isAvailable && (
          <>
            <h2 className="desktop-heading-title5 text-restructure-primary">
              {formatterPrice(sellerOffer.price)}
            </h2>
            {sellerOffer.hasDiscount && (
              <p className="mobile-body-regular-text3 text-restructure-tertiary line-through">
                {formatterPrice(sellerOffer.listPrice)}
              </p>
            )}
            {sellerOffer?.cashback && !isB2B && (
              <p className="desktop-overline-medium text-restructure-success ml-auto mr-0">
                {sellerOffer.cashback}
              </p>
            )}
          </>
        )}
      </div>
      <Installment />
    </div>
  )
}
