import { memo, useContext, useState } from 'react'
import Icon from 'src/components/ui/Icon'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import { ProductContext } from '../../contexts'
import { Resumer } from './Resumer'
import { ReviewsList } from './ReviewsList'
import ModalReviews from './ModalReviews'

import './styles.scss'

function CustomerReviews() {
  const {
    currentSku: {
      manufacturerCode,
      sellerDefault: { sellerId },
    },
    product: { review },
  } = useContext(ProductContext)

  const decathlonSellerId = '1'
  const sellerIsDecathlon = sellerId === decathlonSellerId

  const [showModalAllReviews, setShowModalAllReviews] = useState(false)

  const notesConvertedToKeyValue = Object.entries(review?.notes ?? {}).map(
    (item) => {
      const mapKey: { [key: string]: number } = {
        one: 1,
        two: 2,
        three: 3,
        four: 4,
        five: 5,
      }

      return {
        ...item[1],
        key: mapKey[item[0]],
      }
    }
  )

  const reviewEventTrack = makeEventTrack({
    eventAction: 'ver todas as avaliações',
    eventPage: 'pagina de produto',
  })

  if (!sellerIsDecathlon) {
    return <></>
  }

  return (
    <section
      className="mt-20 w-full mx-auto bg-restructure-background-secondary py-xl restructure-small-desktop:py-14"
      id="customer_reviews_section"
      data-testid="customerReviews"
    >
      <div className="max-w-[1280px] m-auto px-md">
        <div className="flex flex-row justify-between">
          <p
            aria-label="Titulo da pagina, avaliações do produto"
            className="product-avaliations desktop-heading-title4 text-restructure-primary"
          >
            Avaliações do produto
          </p>
          {review?.reviews && review.reviews.length >= 1 && (
            <>
              <button
                data-testid="seeAllReviewsBtn"
                aria-label="Botão para ver todas as avaliações"
                className="desktop-body-semibold-text2 text-restructure-action items-center gap-x-2  cursor-pointer restructure-tablet:flex hidden"
                onClick={() => {
                  setShowModalAllReviews((state) => !state)
                  sendEvent(reviewEventTrack)
                }}
              >
                Ver todas as avaliações
                <Icon name="ArrowRightNew" width={24} height={24} />
              </button>

              {showModalAllReviews && (
                <ModalReviews
                  isOpen={showModalAllReviews}
                  setShowModalAllReviews={setShowModalAllReviews}
                  reviewList={review?.reviews}
                />
              )}
            </>
          )}
        </div>

        <div className="gap-6 flex flex-col restructure-tablet:flex-row mt-md restructure-tablet:mt-xxl">
          <Resumer
            totalReviews={review?.totalReviews}
            ratingNote={review?.totalRatingsAverageNote}
            notes={notesConvertedToKeyValue}
            productCode={manufacturerCode}
          />

          {!!review?.totalReviews && (
            <div className="flex-1" data-testid="reviewsList">
              <ReviewsList reviewList={review.reviews} />
              <button
                data-testid="seeAllReviewsButtonMobile"
                onClick={() => {
                  setShowModalAllReviews(!showModalAllReviews)

                  sendEvent(reviewEventTrack)
                }}
                aria-label="Botão para ver todas as avaliações"
                className="desktop-body-semibold-text2 flex flex-row items-center text-center
              rounded-[360px] justify-center
              mt-4 py-4 px-4 w-full
              border border-restructure-border-primary restructure-tablet:hidden gap-2"
              >
                Ver todas as avaliações
                <Icon name="ArrowRight" width={20} height={20} />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default memo(CustomerReviews)
